<template>
  <div id="ot-sdk-cookie-policy" class="cookie-policy"></div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

onMounted(() => {
  window.OneTrust?.initializeCookiePolicyHtml()
})
</script>

<style lang="scss" scoped>
/* stylelint-disable declaration-no-important */
.cookie-policy {
  :deep(#ot-sdk-cookie-policy-v2) {
    @extend %body;

    * {
      color: var(--c-text) !important;
      line-height: 1.65;
    }
  }

  :deep(#cookie-policy-title) {
    @extend %h3;
  }

  :deep(#cookie-policy-description),
  :deep(p) {
    font-size: 1em !important;
  }
}
</style>
