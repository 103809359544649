declare global {
  interface Window {
    dataLayer?: DataLayer[]
    OneTrust?: {
      changeLanguage: (lang: string) => void
      ToggleInfoDisplay: () => void
      initializeCookiePolicyHtml: () => void
    }
  }
}

type LayerValue = string | boolean | number | (() => void) | undefined
export interface DataLayer {
  [key: string]: LayerValue
  eventCallback?: () => void
}

const shouldTrack = ['staging', 'production'].includes(
  import.meta.env.VITE_RELEASE as string
)

const camelToSnake = (str: string) =>
  str.replace(/([A-Z])/g, '_$1').toLowerCase()

// Remove <strong>, <em>, \n, etc
export const stripHTML = (str: string): string => {
  const div = document.createElement('div')

  div.innerHTML = str.replace(/\n/g, '')

  return div.textContent || str
}

export const push = (dataLayer: DataLayer) => {
  if (import.meta.env.SSR) {
    return
  }

  // myKey -> my_key
  const formattedLayer: DataLayer = {}

  Object.keys(dataLayer).forEach(key => {
    let formattedKey = key

    // Avoid UA properties
    if (
      ![
        'event',
        'eventCategory',
        'eventAction',
        'eventLabel',
        'eventCallback',
      ].includes(key)
    ) {
      formattedKey = camelToSnake(key)
    }

    formattedLayer[formattedKey] = dataLayer[key as keyof DataLayer]
  })

  if (shouldTrack) {
    window.dataLayer?.push(formattedLayer)

    if (
      !window.dataLayer ||
      !Object.prototype.hasOwnProperty.call(window.dataLayer, 'push')
    ) {
      // If callback but no GTM (or native array), trigger the callback
      formattedLayer.eventCallback?.()
    }
  } else {
    console.table(formattedLayer)
    dataLayer.eventCallback?.()
  }
}

export const pushUA = (layerUA: {
  event: string
  eventCategory: string
  eventAction: string
}) => {
  push({
    ...layerUA,
    eventLabel: window.location.pathname,
  })
}

export const pushForm = (
  status: 'start' | 'click' | 'error' | 'success',
  data?: FormData
) => {
  const layer: DataLayer = {
    formStatus: status,
  }

  switch (status) {
    case 'start':
    case 'click':
    case 'error':
      layer.event = 'form_log'

      push(layer)
      break
    case 'success':
      if (data) {
        // Fill your layer here!
        // layer.event = 'contact_form'
        // layer.formType = data.get('form_type') as string
      } else {
        layer.event = 'form_log'
      }

      push(layer)
      break
    default:
  }
}

export const formStart = (form: HTMLFormElement) => {
  const initFormStart = () => {
    // const data = new FormData(form)
    form.removeEventListener('focus', checkFocus, true)

    pushForm('start')
    // pushForm('start', data)
  }

  const checkFocus = (e: Event) => {
    if (e.target && [...form.elements].includes(e.target as Element)) {
      initFormStart()
    }
  }

  form.addEventListener('focus', checkFocus, true)
}

// Get utms from localstorage (saved by gtm)
export const getUtms = (): Record<string, string>[] => {
  const names = [
    'utm_medium',
    'utm_source',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ]
  const utms: Record<string, string>[] = []

  for (const name of names) {
    const value = window.localStorage.getItem(name)

    if (value) {
      utms.push({
        name: name.toUpperCase(),
        value,
      })
    }
  }

  return utms
}
