<template>
  <div ref="swiperRef" class="page-transition" aria-hidden="true"></div>
  <transition
    :css="false"
    :mode="mode"
    @leave="onLeave"
    @enter="onEnter"
    @before-enter="onBeforeEnter"
    @after-enter="onAfterEnter"
    @before-leave="onBeforeLeave"
    @after-leave="onAfterLeave"
  >
    <slot></slot>
  </transition>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { ref } from 'vue'

import { hasMotion } from '@/core/prefers'
import { useUiStore } from '@/stores/ui'

import type { PropType } from 'vue'

interface Ease {
  in: gsap.EaseFunction
  out?: gsap.EaseFunction
}

interface Transition {
  name: string
  eases: Record<string, Ease>
  tlIn?: gsap.core.Timeline
  tlOut?: gsap.core.Timeline
  setup: () => void
  initTlIn: () => void
  initTlOut: () => void
}

defineProps({
  mode: {
    type: String as PropType<'default' | 'out-in' | 'in-out' | undefined>,
    default: 'out-in',
  },
})
const emit = defineEmits([
  'before-enter',
  'after-enter',
  'before-leave',
  'after-leave',
])

const ui = useUiStore()
const swiperRef = ref()

const transition: Transition = {
  name: 'page-transition',
  eases: {},
  setup() {
    gsap.registerPlugin(CustomEase)

    const pageInEase = CustomEase.create('custom', 'M0,0,C0.48,0,0,1,1,1')

    this.eases = {
      container: {
        in: pageInEase,
        out: CustomEase.create('custom', 'M0,0,C0,0.71,0.58,1,1,1'),
      },
      logo: {
        in: pageInEase,
      },
    }
  },
  initTlIn() {
    this.tlIn = gsap.timeline()
    this.tlIn.add(() => {
      ui.hasTransition = 'page'
    })

    // Animate in swiper from left to right
    if (swiperRef.value) {
      this.tlIn.fromTo(
        swiperRef.value,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: this.eases.container.in,
          duration: 0.8,
        },
        'start'
      )
    }
  },
  initTlOut() {
    this.tlOut = gsap.timeline()

    // Animate out swiper from left to right
    if (swiperRef.value) {
      this.tlOut.to(swiperRef.value, {
        opacity: 0,
        ease: this.eases.container.out,
        duration: 0.8,
        delay: 0.2,
      })

      // Notify App that page transition animation is over slightly before it actually finishes.
      // When using strong easing, if subsequent animations are waiting to play,
      // it will feel more fluid than waiting for actual end of TL.
      this.tlOut.add(() => {
        ui.hasTransition = 'none'
      }, '-=0.4')
    }
  },
}

if (!import.meta.env.SSR) {
  transition.setup()
}

const onLeave = async (el: Element, done: () => void) => {
  try {
    // if (hasMotion.value && ui.hasTransition === 'page') {
    if (hasMotion.value) {
      transition.tlOut?.isActive() && transition.tlOut?.kill()

      ui.hasTransition = 'page'
      ui.toggleScroll(false)

      !transition.tlIn && transition.initTlIn()

      await transition.tlIn!.invalidate().restart().then()

      window.scrollTo(0, 0)
    }

    done()
  } catch (error) {
    console.error('DEBUG Error onLeave:', error)
  }
}

const onEnter = async (el: Element, done: () => void) => {
  try {
    // if (motionOK.value && animatePageTransition.value) {
    if (hasMotion.value) {
      transition.tlIn?.isActive() && transition.tlIn.kill()

      !transition.tlOut && transition.initTlOut()

      await transition.tlOut!.invalidate().restart().then()

      ui.toggleScroll(true)
    }

    done()
  } catch (error) {
    console.error('DEBUG Error onEnter:', error)
  }
}

const onBeforeEnter = () => {
  emit('before-enter')
}
const onAfterEnter = () => {
  emit('after-enter')
}
const onBeforeLeave = () => {
  emit('before-leave')
}
const onAfterLeave = () => {
  emit('after-leave')
}
</script>

<style lang="scss" scoped>
.page-transition {
  position: fixed;
  z-index: $header-z-index + 2;
  top: 0;
  right: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  height: calc(100 * var(--vh));
  color: $c-black;
  background-color: $c-white;
  pointer-events: none;
  opacity: 0;

  // clip-path: inset(0 100% 0 0);
}

.page-transition__logo {
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.15;
  will-change: transform;
}
</style>
