import { useAxios } from '@monofront/vue-axios-plugin'
import Cookies from 'js-cookie'
import { ref } from 'vue'

import { getApiUrl } from '@/config/app'
import { getLanguageAvailable, getLanguageDefault } from '@/config/languages'
import { fetchResource, updateResource } from '@/core/resource'
import { initI18n } from '@/i18n'
import { useCountry } from '@/plugins/country'
import { useChromeStore } from '@/stores/chrome'
import { useNavigationStore } from '@/stores/navigation'
import { logger } from '@/utils/logger'
import { getPopup } from '@/utils/popups'
import { push } from '@/utils/tracking'

import type { SharedContext } from '@monofront/vite-ssr/utils'
import type { AxiosRequestHeaders } from '@monofront/vue-axios-plugin'
import type { Request, Response } from 'express'
import type { Pinia } from 'pinia'
import type { ResourceError } from '@/stores/resource'
import type { AxiosHeaders, Language, SSRContext } from '@/types'

export const guardError = ref<ResourceError>()

export const createGuards = (
  { router, initialState, request, response, writeResponse }: SSRContext,
  { pinia, i18n }: { pinia: Pinia; i18n: ReturnType<typeof initI18n> }
) => {
  const axios = useAxios<AxiosHeaders>()
  const country = useCountry()
  const languageAvailable = getLanguageAvailable(country)
  const languageDefault = getLanguageDefault(country)

  let isFirstRendering = true

  // Log
  router.beforeEach((to, from, next) => {
    logger.info('[guards] from', from.name, 'to', to.name)

    const navigation = useNavigationStore(pinia)

    navigation.from = from.name
    navigation.to = to.name
    next()
  })

  // Manage env/feature branches
  axios.interceptors.request.use(config => {
    const isAppApiCall = config.url?.includes(getApiUrl(country))
    const isDev = import.meta.env.MODE === 'development'
    const isStaging = import.meta.env.MODE === 'staging'
    const isSSR = import.meta.env.SSR
    let backEnv = 'staging'

    // Do not sepcify epic_env_XXX headers if
    // calling something else than API
    // or is neither in dev or staging
    if (!isAppApiCall || (!isDev && !isStaging)) {
      return config
    }

    if (isSSR) {
      if (request?.headers.cookie) {
        const cookies = request.headers.cookie.split(/\s*;\s*/)
        const envBackStr = cookies.find((c: string) =>
          c.startsWith('epic_env_back')
        )

        if (envBackStr) {
          const [, envBackCookie] = envBackStr.split('=')

          backEnv = envBackCookie
        }
      }
    } else {
      backEnv = Cookies.get('epic_env_back') || backEnv
    }

    if (__FEATURE__ !== 'none') {
      backEnv = __FEATURE__
    }

    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders
    }

    /* eslint-disable */
    config.headers.epic_env_back = backEnv
    /* eslint-enable */

    return config
  })

  // Manage languages
  if (languageAvailable.length > 1) {
    router.beforeEach(async (to, from, next) => {
      const chrome = useChromeStore(pinia)
      const { lang: langCurrent } = from.params as { lang: Language }
      const lang = (to.params.lang as Language) || languageDefault

      if (
        to.meta.state === null &&
        from.name === undefined &&
        to.meta.chrome !== false
      ) {
        // First load on SSR, chrome is fetched and locales are set
        axios.defaults.headers.common['Accept-Language'] = lang
        await chrome.fetchChrome(lang)
        i18n.setLocale(lang, chrome.i18n, false)
      }

      chrome.language = lang

      const langHasChanged = langCurrent !== undefined && langCurrent !== lang
      const langNext = languageAvailable.includes(lang) ? lang : languageDefault

      if (langHasChanged && to.meta.chrome !== false) {
        axios.defaults.headers.common['Accept-Language'] = langNext

        try {
          await chrome.fetchChrome(langNext)

          i18n.setLocale(langNext, chrome.i18n, !import.meta.env.SSR)

          // Update OneTrust language
          window.OneTrust?.changeLanguage(langNext)
        } catch (error) {
          logger.error(error)
        }
      }

      next()
    })
  }

  // Fetch
  // Before each route navigation we request the data needed for showing the page.
  router.beforeEach(async (to, from, next) => {
    if (Boolean(to.meta.state) && isFirstRendering) {
      // This route has state already (from server) so it can be reused.
      // State is always empty in SPA development, but present in SSR development.
      const { resource, error } = initialState

      // Display error view
      if (error && import.meta.env.MODE === 'development') {
        guardError.value = error
      }

      // Hydrate resource store
      resource && updateResource(resource)
      isFirstRendering = false

      return next()
    }

    // Explanation:
    // The first rendering happens in the server. Therefore, when this code runs,
    // the server makes a request to itself (running the code below) in order to
    // get the current page props and use that response to render the HTML.
    // The browser shows this HTML and rehydrates the application, turning it into
    // a normal SPA. After that, subsequent route navigation runs this code below
    // from the browser and get the new page props, which is this time rendered
    // directly in the browser, as opposed to the first page rendering.

    try {
      const [resource, error, headers] = await fetchResource(
        to,
        request as Request
      )

      if (resource) {
        if (
          import.meta.env.MODE === 'production' ||
          import.meta.env.MODE === 'staging'
        ) {
          const mergedHeaders: SharedContext['ServerResponse']['OutgoingHttpHeaders'] =
            {
              ...(response?.getHeaders() || {}),
              'Content-Type': 'text/html',
            }

          if (headers['cache-control']) {
            mergedHeaders['Cache-Control'] = headers['cache-control']
          } else {
            mergedHeaders['Cache-Control'] = 'no-store'
          }
          ;(response as Response)?.set(mergedHeaders)
        }

        if (import.meta.env.SSR && resource.template === 'notfound') {
          writeResponse({
            status: 404,
            headers: {},
          })
        }

        // First SSR rendering, render resource (@see App.vue)
      } else if (error && import.meta.env.MODE === 'development') {
        // Catch fetch errors.
        guardError.value = error
      }

      // This route meta state will be available as props in the page component.
      // This will also be added to the initialState.
      to.meta.state = { resource, error }
    } catch (error) {
      logger.error('[guards]', error)
    }

    return next()
  })

  // GTM - Analytics
  router.afterEach((to, from) => {
    // Only client side, skip first display
    // if (!import.meta.env.SSR) {
    if (!import.meta.env.SSR && from.name) {
      // GTM: page view
      const layer = {
        event: 'page_view',
        pageTitle:
          to.meta.state?.resource?.head?.title ||
          to.meta.state?.resource?.content?.title ||
          document.title,
        pageLocation: window.location.href,
        pagePath: to.fullPath,
      }

      push(layer)
    }
  })

  router.afterEach((_to, _from, failure) => {
    if (failure) {
      console.warn('DEBUG Navigation failed:', failure)
    }
  })

  // Popups
  // Trigger popups
  router.afterEach((to, from) => {
    const { lang } = to.params

    if (to.params.page === 'contact') {
      const isLang = (s: string | string[]): s is 'fr' | 'nl' =>
        typeof s === 'string' && ['fr', 'nl'].includes(s)
      let popup: string | undefined = undefined

      if (!isLang(lang)) {
        return
      }

      if (from.name === 'homepage') {
        popup = getPopup(lang)
      } else if (
        typeof from.params.page === 'string' &&
        typeof from.params.subpage === 'string'
      ) {
        popup = getPopup(
          lang,
          from.params.page,
          from.params.subpage.replace('/', '')
        )
      }

      // eslint-disable-next-line no-eval
      popup && eval(popup)
      console.log('Execute script:', popup)
    }
  })
}
